
import { useMainStore } from "@/stores/useMainStore";
import { defineComponent } from "vue";
import NavigationTitle from "../components/NavigationTitle.vue";

export default defineComponent({
  name: "Listen",
  components: {
    NavigationTitle,
  },
  setup() {
    const mainStore = useMainStore();
    return { mainStore };
  },
  mounted() {
    const albums = document.querySelector(".albums");
    if (albums) albums.scrollLeft = 20;
  },
});
