
import { useMainStore } from "@/stores/useMainStore";
import { defineComponent } from "vue";
import NavigationTitle from "../components/NavigationTitle.vue";

export default defineComponent({
  name: "About",
  components: {
    NavigationTitle,
  },
  setup() {
    const mainStore = useMainStore();
    return { mainStore };
  },
});
