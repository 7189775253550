import { createElementVNode as _createElementVNode, normalizeStyle as _normalizeStyle, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-2458c317"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "intro" }
const _hoisted_2 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("video", {
      autoplay: "",
      muted: "",
      playsinline: "",
      onEnded: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('introEnded'))),
      style: _normalizeStyle({
        maxWidth: _ctx.mainStore.viewPortConfig.width + 'px',
        height: _ctx.mainStore.viewPortConfig.height + 'px',
      })
    }, [
      _createElementVNode("source", {
        src: 
          require(`@/assets/intro/${
            _ctx.mainStore.viewportHorizontal ? 'horizontal' : 'vertical'
          }.mp4`)
        ,
        type: "video/mp4"
      }, null, 8, _hoisted_2)
    ], 36)
  ]))
}