import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-48fe0ee2"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "listen textured-background" }
const _hoisted_2 = ["src"]
const _hoisted_3 = ["src"]
const _hoisted_4 = ["href"]
const _hoisted_5 = ["src"]
const _hoisted_6 = { class: "info" }
const _hoisted_7 = { class: "title condensed-bolder" }
const _hoisted_8 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_NavigationTitle = _resolveComponent("NavigationTitle")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_NavigationTitle),
    _createElementVNode("img", {
      src: require(`@/assets/listen/transparent-rect-left.png`),
      class: "transparent-rect left"
    }, null, 8, _hoisted_2),
    _createElementVNode("img", {
      src: require(`@/assets/listen/transparent-rect-right.png`),
      class: "transparent-rect right"
    }, null, 8, _hoisted_3),
    _createElementVNode("div", {
      class: _normalizeClass(["albums", { justify: _ctx.mainStore.justifyAlbums }])
    }, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.mainStore.$state.albums, (album, index) => {
        return (_openBlock(), _createElementBlock("a", {
          key: index,
          class: _normalizeClass(["album", {
          first: index === 0,
          last: index === _ctx.mainStore.$state.albums.length - 1,
        }]),
          href: album.href,
          target: "_blank",
          rel: "noreferrer noopener"
        }, [
          _createElementVNode("img", {
            src: require(`@/assets/listen/${album.cover}`),
            class: "cover"
          }, null, 8, _hoisted_5),
          _createElementVNode("div", _hoisted_6, [
            _createElementVNode("p", _hoisted_7, _toDisplayString(album.title), 1),
            _createElementVNode("img", {
              src: require(`@/assets/listen/line.svg`),
              class: "line"
            }, null, 8, _hoisted_8)
          ])
        ], 10, _hoisted_4))
      }), 128))
    ], 2)
  ]))
}