
import { useMainStore } from "@/stores/useMainStore";
import { defineComponent } from "vue";

export default defineComponent({
  name: "NavigationTitle",
  components: {},
  setup() {
    const mainStore = useMainStore();
    return { mainStore };
  },
});
