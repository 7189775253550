
import { defineComponent } from "vue";
import { useMainStore } from "./stores/useMainStore";
import Logo from "./components/Logo.vue";
import Footer from "./components/Footer.vue";
import Intro from "./components/Intro.vue";

export default defineComponent({
  name: "App",
  components: {
    Logo,
    Footer,
    Intro,
  },
  setup() {
    const mainStore = useMainStore();
    mainStore.configureViewport(window.innerWidth, window.innerHeight);
    window.addEventListener("resize", () => {
      mainStore.configureViewport(window.innerWidth, window.innerHeight);
    });

    return { mainStore };
  },
  methods: {
    hideIntroVideo() {
      this.mainStore.$state.intro = false;
    },
  },
});
