import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle, vShow as _vShow, createElementVNode as _createElementVNode, withDirectives as _withDirectives, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Intro = _resolveComponent("Intro")!
  const _component_Logo = _resolveComponent("Logo")!
  const _component_router_view = _resolveComponent("router-view")!
  const _component_Footer = _resolveComponent("Footer")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (_ctx.mainStore.$state.intro)
      ? (_openBlock(), _createBlock(_component_Intro, {
          key: 0,
          onIntroEnded: _ctx.hideIntroVideo
        }, null, 8, ["onIntroEnded"]))
      : _createCommentVNode("", true),
    _withDirectives(_createElementVNode("div", null, [
      _createVNode(_component_Logo),
      _createVNode(_component_router_view, {
        class: _normalizeClass(["main-container", { horizontal: _ctx.mainStore.viewportHorizontal }]),
        style: _normalizeStyle({
        width: _ctx.mainStore.viewPortConfig.width + 'px',
        minHeight: _ctx.mainStore.viewPortConfig.height + 'px',
      })
      }, null, 8, ["class", "style"]),
      _createVNode(_component_Footer)
    ], 512), [
      [_vShow, !_ctx.mainStore.$state.intro]
    ])
  ], 64))
}