import { resolveComponent as _resolveComponent, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-82139718"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "links textured-background" }
const _hoisted_2 = { class: "links-container" }
const _hoisted_3 = ["href"]
const _hoisted_4 = { class: "condensed-bolder" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_NavigationTitle = _resolveComponent("NavigationTitle")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_NavigationTitle),
    _createElementVNode("div", _hoisted_2, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.mainStore.$state.links, (link) => {
        return (_openBlock(), _createElementBlock("a", {
          key: link.title,
          class: "link-item",
          target: "_blank",
          rel: "noreferrer noopener",
          href: link.href
        }, [
          _createElementVNode("p", _hoisted_4, _toDisplayString(link.title), 1),
          _createElementVNode("p", null, " - " + _toDisplayString(link.description), 1)
        ], 8, _hoisted_3))
      }), 128))
    ])
  ]))
}