import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-7f544ac7"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "home" }
const _hoisted_2 = { class: "home-navigation" }
const _hoisted_3 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.mainStore.$state.navigation, (navigationLink) => {
        return (_openBlock(), _createBlock(_component_router_link, {
          key: navigationLink.title,
          to: navigationLink.route
        }, {
          default: _withCtx(() => [
            _createElementVNode("img", {
              src: require(`@/assets/navigation/${navigationLink.image}`),
              class: "nav-link"
            }, null, 8, _hoisted_3)
          ]),
          _: 2
        }, 1032, ["to"]))
      }), 128))
    ])
  ]))
}