
import { defineComponent } from "vue";
import { useMainStore } from "../stores/useMainStore";

export default defineComponent({
  name: "Intro",
  setup() {
    const mainStore = useMainStore();

    return { mainStore };
  },
  components: {},
});
