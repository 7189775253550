import { defineStore } from "pinia";
import { useRoute } from "vue-router";

export const useMainStore = defineStore("mainStore", {
  state: () => ({
    intro: true,
    viewPortConfig: {
      width: 0,
      height: 0,
    },
    albums: [
      {
        title: "DSTRZN",
        cover: "dstrzn.png",
        href: "https://open.spotify.com/track/1EVUKhNvztQDBOkHdCcuUK?si=690c247d9fe64365",
      },
      {
        title: "SEASON 1",
        cover: "season-1.png",
        href: "https://soundcloud.com/dirtysockscrew/sets/season-01?si=c79af38e633d40f48ef642494a91b74e&utm_source=clipboard&utm_medium=text&utm_campaign=social_sharing",
      },
      {
        title: "SEASON 2",
        cover: "season-2.png",
        href: "https://soundcloud.com/dirtysockscrew/sets/season-02?si=b441589513fd4788b10bdb28fb555f5d&utm_source=clipboard&utm_medium=text&utm_campaign=social_sharing",
      },
      {
        title: "KINDER GARTEN",
        cover: "kinder-garten.png",
        href: "https://soundcloud.com/dirtysockscrew/kindergarten-mixtape?si=37e5f47903e441d5bfd2a106540ccdc7&utm_source=clipboard&utm_medium=text&utm_campaign=social_sharing",
      },
    ],
    links: [
      {
        title: "INSTAGRAM",
        description: "https://bit.ly/3Bcldyp",
        href: "https://www.instagram.com/drtscks/",
      },
      {
        title: "SPOTIFY",
        description: "https://spoti.fi/3qy5apP",
        href: "https://spoti.fi/3qy5apP",
      },
      {
        title: "SOUNDCLOUD",
        description: "https://bit.ly/3qzWNtK",
        href: "https://bit.ly/3qzWNtK",
      },
      {
        title: "MAIL",
        description: "dirtysockscrew@gmail.com",
        href: "mailto: dirtysockscrew@gmail.com",
      },
    ],
    navigation: [
      {
        title: "listen",
        route: "/listen",
        image: "listen.png",
      },
      {
        title: "link",
        route: "/link",
        image: "link.png",
      },
      {
        title: "about",
        route: "/about",
        image: "about.png",
      },
    ],
  }),
  getters: {
    currentPath: (): string => {
      return useRoute().path;
    },
    justifyAlbums: (state): boolean => {
      return state.viewPortConfig.width >= 1190;
    },
    viewportHorizontal: (state): boolean => {
      return state.viewPortConfig.width > state.viewPortConfig.height;
    },
    collettivoImgHeight: (state): string => {
      const baseHeight = 320;
      const breakpointHeight = 750;

      if (state.viewPortConfig.height >= breakpointHeight)
        return `${baseHeight}px`;

      const toBeRemoved = breakpointHeight - state.viewPortConfig.height;
      return `${baseHeight - toBeRemoved}px`;
    },
    aboutContainerHeight: (state): string => {
      return `${state.viewPortConfig.height * 0.78}px`;
    },
    aboutContainerMargin: (state): string => {
      return `${state.viewPortConfig.height * 0.15}px`;
    },
  },
  actions: {
    configureViewport(width: number, height: number): void {
      this.viewPortConfig.width = width;
      this.viewPortConfig.height = height;
    },
  },
});
